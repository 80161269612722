import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget10,
  MixedWidget8,
  TilesWidget1,
  TilesWidget4,
  TilesWidget2,
  TilesWidget5,
  TilesWidget6,
  TilesWidget7,
  TilesWidget3,
} from '../../../_metronic/partials/widgets'

const DashboardPage = () => (
  <>
    <div className='row g-5 g-xl-8'>
     
      <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          // strokeColor='#cb1e46'
                    />
        <MixedWidget11
         className='card-xxl-stretch-50 mb-5 mb-xl-8'
         chartColor='primary'
         chartHeight='150px' 
        />
      </div>
    </div>

      <div className='col-xl-8'>
        {/* <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-3'>
            <TilesWidget1 className='card-xl-stretch' />
          </div>
          <div className='col-xl-9'>
            <TilesWidget4 className='card-xl-stretch' />
          </div>
        </div> */}

        <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-6'>
            {/* <TilesWidget2 className='card-xl-stretch mb-5 mb-xl-8' /> */}
            <div className='row gx-5 gx-xl-8'>
              <div className='col-xl-6' style={{padding:"10px"}}>
                <TilesWidget5
                 className='card-xxl-stretch mb-xl-3'
                 chartColor='blue'
                 chartHeight='150px'
                />
              </div>
            </div>
          </div>
          {/* <div className='col-xl-6'>
            <TilesWidget3 className='card-xl-stretch mb-5 mb-xl-8' />
          </div> */}
        </div>
        <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-6'>
            {/* <TilesWidget2 className='card-xl-stretch mb-5 mb-xl-8' /> */}
            <div className='row gx-5 gx-xl-8'>
              <div className='col-xl-6' style={{padding:"10px"}}>
                <TilesWidget6
                 className='card-xxl-stretch mb-xl-3'
                 chartColor='blue'
                 chartHeight='150px'
                />
              </div>
            </div>
          </div>
          {/* <div className='col-xl-6'>
            <TilesWidget3 className='card-xl-stretch mb-5 mb-xl-8' />
          </div> */}
        </div>
        <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
          <div className='col-xl-6'>
            {/* <TilesWidget2 className='card-xl-stretch mb-5 mb-xl-8' /> */}
            <div className='row gx-5 gx-xl-8'>
              <div className='col-xl-6' style={{padding:"10px"}}>
                <TilesWidget7
                 className='card-xxl-stretch mb-xl-3'
                 chartColor='blue'
                 chartHeight='150px'
                />
              </div>
            </div>
          </div>
          {/* <div className='col-xl-6'>
            <TilesWidget3 className='card-xl-stretch mb-5 mb-xl-8' />
          </div> */}
        </div>
      </div>
       <div className='col-xl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
    </div>

    

  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
