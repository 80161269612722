import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgotPassword`

// Server should return AuthModel
export function login(userName: string, password: string) {
  return axios.post(LOGIN_URL, {
    userName,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  name: string,
  userName: string,
  password: string,
  userType:string,
) {
  return axios.post(REGISTER_URL, {
    email,
    name,
    userName,
    password,
    userType
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(userName: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    userName,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
