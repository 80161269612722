import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { ProjectDetailsWrapper } from "../pages/projectDetails/projectDetails";
import { AgentDetailsWrapper } from "../pages/agentDetails/agentDetails";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import ProjectWrapper from "../pages/projects/projects";
import AgentWrapper from "../pages/agent/agent";

const PrivateRoutes = () => {
  

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="project" element={<ProjectWrapper />} />
        <Route path="project-details" element={<ProjectDetailsWrapper />} />
        <Route path="agent-details" element={<AgentDetailsWrapper />} />
        <Route path="agent" element={<AgentWrapper />} />
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--kt-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
