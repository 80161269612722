import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {UsersListWrapper} from './users-list/UsersList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: 'agent-details',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AgentPage = () => {
  return (
    <>
              <PageTitle breadcrumbs={usersBreadcrumbs}>Agent list</PageTitle>
              <UsersListWrapper />
            </>
         
     
  )
}

export default AgentPage 
