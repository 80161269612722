import { FC, useState } from "react";
import "./FormSteps.css";
import axios from "axios";
import { useListView } from "../core/ListViewProvider";

const FormSteps: FC = () => {
  const { setItemIdForUpdate } = useListView();
  const tags = ["Tag1", "Tag2", "Tag3"];
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [unit, setUnit] = useState("");
  const [location, setLocation] = useState("");
  const [scenario, setScenario] = useState("");
  const [field1, setField1] = useState("");
  const [siem, setSiem] = useState("false");
  const [epr, setEpr] = useState("false");
  const [antivirus, setAntivirus] = useState("false");
  const [checkedState, setCheckedState] = useState(
    new Array(tags.length).fill(false)
  );

  const [formStep, setFormStep] = useState("stepOne");

  const handleOnChange = (position: number) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };

  const onContinueHandler = (e: any) => {
    e.preventDefault();
    setFormStep("stepTwo");
  };

  const onBackHandler = (e: any) => {
    e.preventDefault();
    setFormStep("stepOne");
  };
  const onSubmitHandler = (e: any) => {
    e.preventDefault();
    console.log(checkedState);
    const tagsArray = [];
    for (let i = 0; i < tags.length; i++) {
      checkedState[i] && tagsArray.push(tags[i]);
    }
    const data = {
      name: name,
      category: category,
      scenario: scenario,
      tags: tagsArray,
    };
    let token:any=localStorage.getItem('authToken');
    axios
      .post("https://dev-v2.hyacinth.app/v1/api/project", data, {
        headers: {
          authorization:token
        },
      })
      .then((res) => {
        console.log(res);
        setItemIdForUpdate(undefined);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="mb-12 d-flex justify-content-center">
        <button
          onClick={onBackHandler}
          className={`me-8 stepper-step ${
            formStep === "stepOne" && "selected"
          }`}
        >
          Step One
        </button>
        <button
          onClick={onContinueHandler}
          className={`stepper-step ${formStep === "stepTwo" && "selected"}`}
        >
          Step Two
        </button>
      </div>
      {formStep === "stepOne" && (
        <div className="container">
          <form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Category
              </label>
              <input
                type="text"
                name="description"
                id="description"
                className="form-control"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="unit" className="form-label">
                Scenario
              </label>
              <input
                type="text"
                name="unit"
                id="unit"
                className="form-control"
                value={scenario}
                onChange={(e) => setScenario(e.target.value)}
                required
              />
            </div>
            <div className="mt-12 mb-3 d-flex justify-content-end">
              <button
                type="submit"
                onClick={onContinueHandler}
                className="btn btn-primary"
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      )}
      {formStep === "stepTwo" && (
        <div className="container">
          <form>
            {/* <div>
              <label htmlFor="location" className="form-label">
                Location
              </label>
              <input
                type="text"
                name="location"
                id="location"
                className="form-control"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
            </div> */}
            {/* <div>
              <label htmlFor="branch" className="form-label mt-2">
                Branch
              </label>
              <input
                type="text"
                name="branch"
                id="branch"
                className="form-control"
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                required
              />
            </div> */}
            <div>
              <p className="form-label mt-4">Tags</p>
              {tags.map((tag, index) => {
                return (
                  <div key={index}>
                    <div className="left-section">
                      <input
                        type="checkbox"
                        id={index.toString()}
                        name={tag}
                        value={tag}
                        checked={checkedState[index]}
                        onChange={() => handleOnChange(index)}
                      />
                      <label htmlFor={`${index}`} className="ms-2">
                        {tag}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <div>
              <p className="form-label mt-4">Custom Fields</p>
              <label htmlFor="field1" className="form-label">
                Field 1
              </label>
              <input
                type="text"
                name="field1"
                id="field1"
                className="form-control"
                value={field1}
                onChange={(e) => setField1(e.target.value)}
                required
              />
            </div> */}
            {/* <div>
              <p className="form-label mt-4">Question Answers</p>
              <p className="form-label mt-4">Do you have SIEM?</p>
              <input
                type="radio"
                name="siem"
                value={"true"}
                checked={siem === "true"}
                onChange={(e) => setSiem(e.target.value)}
              />
              <label htmlFor="field1" className="form-label mx-2">
                Yes
              </label>
              <input
                type="radio"
                name="siem"
                value={"false"}
                checked={siem === "false"}
                onChange={(e) => setSiem(e.target.value)}
              />
              <label htmlFor="field1" className="form-label mx-2">
                No
              </label>
              <p className="form-label mt-4">Do you have EPR?</p>
              <input
                type="radio"
                name="epr"
                value={"true"}
                checked={epr === "true"}
                onChange={(e) => setEpr(e.target.value)}
              />
              <label htmlFor="field1" className="form-label mx-2">
                Yes
              </label>
              <input
                type="radio"
                name="epr"
                value={"false"}
                checked={epr === "false"}
                onChange={(e) => setEpr(e.target.value)}
              />
              <label htmlFor="field1" className="form-label mx-2">
                No
              </label>
              <p className="form-label mt-4">Do you have AntiVirus?</p>
              <input
                type="radio"
                name="antivirus"
                value={"true"}
                checked={antivirus === "true"}
                onChange={(e) => setAntivirus(e.target.value)}
              />
              <label htmlFor="field1" className="form-label mx-2">
                Yes
              </label>
              <input
                type="radio"
                name="antivirus"
                value={"false"}
                checked={antivirus === "false"}
                onChange={(e) => setAntivirus(e.target.value)}
              />
              <label htmlFor="field1" className="form-label mx-2">
                No
              </label>
            </div> */}
            <div className="mt-12 mb-3 d-flex justify-content-end">
              <button
                type="submit"
                onClick={onBackHandler}
                className="btn btn-primary me-3"
              >
                Back
              </button>
              <button
                type="submit"
                onClick={onSubmitHandler}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default FormSteps;
