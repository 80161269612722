import {useIntl} from 'react-intl'
import UsersPage from "../../modules/project/UsersPage";


const ProjectsPage = () => (
  
  <div className="container">
    <UsersPage/>
  </div>

)

const ProjectWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <ProjectsPage />
    </>
  )
}

export default ProjectWrapper
