/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { KTSVG } from '../../../helpers'
import { getCSSVariableValue } from '../../../assets/ts/_utils'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
}

const MixedWidget2: React.FC<Props> = ({ className, chartColor, chartHeight, strokeColor }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode();
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    setTimeout(() => {
      initChart(130, 10, 120);
    }, 10);
  };

  useEffect(() => {
    refreshChart();
  }, [mode]);

  return (
    <div className={`card ${className}`}>
      <div className={`card-header border-0 py-5 bg-${chartColor}`}>
        <h3 className='card-title fw-bold text-white'>Overall Scores</h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div>
      </div>
      <div className='card-body p-0'>
        {/* <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
        >
          
        </div> */}
        <div className="col-xs-12 col-sm-12 col-md-12">
          <div
            className={`card ${className}  card-header border-0 py-5`}
            style={{ height: "222px", width: "420px, ms-4" }}
          >
            <h2 className={`card-title fw-bold color-${chartColor}`}>97%</h2>
              {/* <h2 className="`card-title fw-bold color-${chartColor} text-center`" */}
            <div className="card-toolbar">

            </div>

            <div className="d-flex flex-row mt-5 justify-content-center">

              <div className="d-flex me-5 pt-2">
                <div
                  id="kt_card_widget_17_chart"
                  ref={chartRef}
                  style={{ minWidth: 11 + "px", minHeight: 11 + "px" }}
                  data-kt-size={70}
                  data-kt-line={11}
                >

                </div>
              </div>
              <div className="d-flex flex-column content-justify-end  px-8">
                <div className="d-flex fw-semibold align-items-center">
                  <div className="bullet w-8px h-3px rounded-2 bg-success me-3"></div>
                  <div className="text-gray-500 flex-grow-1 me-4">Active</div>
                </div>
                <div className="d-flex fw-semibold align-items-center my-2">
                  <div className="bullet w-8px h-3px rounded-2 bg-primary me-3"></div>
                  <div className="text-gray-500 flex-grow-1 me-4">In Progress</div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
      {/* end::Body */}
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145
) {
  const el = document.getElementById("kt_card_widget_17_chart");
  if (!el) {
    return;
  }
  el.innerHTML = "";

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  };

  const canvas = document.createElement("canvas");
  const span = document.createElement("span");

  // @ts-ignore
  if (typeof G_vmlCanvasManager !== "undefined") {
    // @ts-ignore
    G_vmlCanvasManager.initElement(canvas);
  }

  const ctx = canvas.getContext("2d");
  canvas.width = canvas.height = options.size;

  el.appendChild(span);
  el.appendChild(canvas);

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2); // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI); // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2;

  const drawCircle = function (
    color: string,
    lineWidth: number,
    percent: number
  ) {
    percent = Math.min(Math.max(0, percent || 1), 1);
    if (!ctx) {
      return;
    }

    ctx.beginPath();
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false);
    ctx.strokeStyle = color;
    ctx.lineCap = "round"; // butt, round or square
    ctx.lineWidth = lineWidth;
    ctx.stroke();
  };
  drawCircle("#E4E6EF", options.lineWidth, 100 / 100);
  drawCircle(getCSSVariableValue("--kt-primary"), options.lineWidth, 270 / 150);
  drawCircle(getCSSVariableValue("--kt-success"), options.lineWidth, 10 / 250);
};

export { MixedWidget2 }
