import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
    MixedWidget2,
    MixedWidget10,
    MixedWidget11,
    ListsWidget5,
    MixedWidget8,
    TilesWidget5,
  } from '../../../_metronic/partials/widgets';

  const ProjectDetailsPage = () => (
    <>
      <div className='row g-5 g-xl-8'>
       
        <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <MixedWidget2
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='200px'
            strokeColor='#cb1e46'
          />
        </div>
        <div className='col-xxl-4'>
          <ListsWidget5 className='card-xxl-stretch' />
        </div>
        <div className='col-xxl-4'>
          <MixedWidget10
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
          />
          <MixedWidget11
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
          />
        </div>
      </div>
  
        <div className='col-xl-8'>
          {/* <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
            <div className='col-xl-3'>
              <TilesWidget1 className='card-xl-stretch' />
            </div>
            <div className='col-xl-9'>
              <TilesWidget4 className='card-xl-stretch' />
            </div>
          </div> */}
  
          <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
            <div className='col-xl-6'>
              {/* <TilesWidget2 className='card-xl-stretch mb-5 mb-xl-8' /> */}
              <div className='row gx-5 gx-xl-8'>
                <div className='col-xl-6' style={{padding:"10px"}}>
                  <TilesWidget5
                    // className='card-xxl-stretch bg-primary'
                    // svgIcon='/media/icons/duotune/general/gen025.svg'
                    // titleClass='text-white'
                    // descriptionClass='text-white'
                    // iconClass='svg-icon-white'
                    // title='790'
                    // description='New Products'
                    className='card-xxl-stretch mb-xl-3'
                    chartColor='success'
                    chartHeight='150px'
                  />
                </div>
                <div className='col-xl-6' style={{padding:"10px"}}>
                  <TilesWidget5
                    // className='card-xxl-stretch bg-body'
                    // svgIcon='/media/icons/duotune/general/gen002.svg'
                    // titleClass='text-dark'
                    // descriptionClass='text-muted'
                    // iconClass='svg-icon-success'
                    // title='8,600'
                    // description='New Customers'
                    className='card-xxl-stretch mb-xl-3'
                    chartColor='success'
                    chartHeight='150px'
                  />
                </div>
              </div>
            </div>
            {/* <div className='col-xl-6'>
              <TilesWidget3 className='card-xl-stretch mb-5 mb-xl-8' />
            </div> */}
          </div>
        </div>
         <div className='col-xl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
      </div>
  
      
  
    </>
  )

  const ProjectDetailsWrapper = () => {
    const intl = useIntl()
    return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Project details'})}</PageTitle>
        <ProjectDetailsPage />
      </>
    )
  }
  
  export {ProjectDetailsWrapper}