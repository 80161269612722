/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
}

const ListsWidget5: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
      
        {/* <h3 className='card-title align-items-start flex-column'>
        <KTSVG path='/media/icons/tv.svg' className='svg-icon-2 bg-color-blue' />
          <span className='fw-bold mb-2 text-dark'>Number of devices</span>
          <span className='text-muted fw-semibold fs-7'>Some additional info</span>
        </h3> */}
         <div className="row">
         <div className="col-md-6" style={{backgroundColor:"#EEEFF3", borderRadius:'6px', width:"14%"}}>
         <KTSVG path='/media/icons/tv.svg' className='svg-icon-2' />
         </div>
         <div className="col-md-6">
         <span className='fw-bold text-dark'>Number of devices </span> 4
         </div>
         <span className='text-muted fw-semibold fs-7 l-2' style={{marginLeft: "12%"}}>Some additional info</span>
      </div>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {/* begin::Item */}
          <div className='timeline-item'>
            <div className='fw-bold text-gray-800 w-25'>cps-fin-internal</div>
            <div className='fw-mormal text-muted' style={{backgroundColor: '#C8EDFF', borderRadius: '6px', margin:"3px"}}>
            <KTSVG path='/media/icons/device.svg' className='svg-icon-2 bg-color-blue' />
           
            </div>
            <br/>
            <div className='fw-mormal text-muted' style={{backgroundColor: '#FFC8C8', borderRadius: '6px', margin:"3px"}}>
            <KTSVG path='/media/icons/wifi.svg' className='svg-icon-2 bg-color-blue' />
            </div>
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='timeline-item' >
            <div className='fw-bold text-gray-800 w-25'>cps-fin-internal</div>
            <div className='fw-mormal text-muted' style={{backgroundColor: '#E0FFC8', borderRadius: '6px', margin:"3px"}}>
            <KTSVG path='/media/icons/round.svg' className='svg-icon-2 bg-color-blue' />
            </div>
            <div className='fw-mormal text-muted' style={{backgroundColor: '#C8EDFF', borderRadius: '6px', margin:"3px"}}>
            <KTSVG path='/media/icons/signal_wifi.svg' className='svg-icon-2 bg-color-blue' />
            </div>
          </div>
          {/* end::Item */}
          {/* begin::Item */}
          <div className='timeline-item' >
            <div className='fw-bold text-gray-800 w-25'>cps-fin-internal</div>
            <div className='fw-mormal text-muted' style={{backgroundColor: '#C8EDFF', borderRadius: '6px', margin:"3px"}}>
            <KTSVG path='/media/icons/device.svg' className='svg-icon-2 bg-color-blue' />
            </div>
            <div className='fw-mormal text-muted' style={{backgroundColor: '#C8EDFF', borderRadius: '6px', margin:"3px"}}>
            <KTSVG path='/media/icons/signal_wifi.svg' className='svg-icon-2 bg-color-blue' />
            </div>
          </div>
          <div className='timeline-item' >
            <div className='fw-bold text-gray-800 w-25'>cps-fin-internal</div>
            <div className='fw-mormal text-muted' style={{backgroundColor: '#FFC8C8', borderRadius: '6px', margin:"3px"}}>
            <KTSVG path='/media/icons/plus.svg' className='svg-icon-2 bg-color-blue' />
            </div>
            <div className='fw-mormal text-muted' style={{backgroundColor: '#C8EDFF', borderRadius: '6px', margin:"3px"}}>
            <KTSVG path='/media/icons/signal_wifi.svg' className='svg-icon-2 bg-color-blue' />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export {ListsWidget5}
