import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {initialUser, User} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createUser, updateUser} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import "./popup.scss";

type Props = {
  isUserLoading: boolean
  user: User
}

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
})

const UserEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [userForEdit] = useState<User>({
    ...user,
    avatar: user.avatar || initialUser.avatar,
    role: user.role || initialUser.role,
    position: user.position || initialUser.position,
    name: user.name || initialUser.name,
    email: user.email || initialUser.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateUser(values)
        } else {
          await createUser(values)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
    
      <div className="card-body d-flex flex-column">

        <div className="d-flex flex-column justify-content-between mb-6 px-8 flex-sm-row">
          <div className="d-flex flex-column align-items-start mb-4 mb-sm-0">
            <div className="text-white-400 my-2">Operating System</div>
            <div className="d-flex">
              <div className="bg-primary rounded p-2 pt-1 me-3">
                {/* <Windows className="svg" /> */}
              </div>
              <div className="bg-success rounded p-2 pt-1 me-3">
                {/* <MacCommand /> */}
              </div>
              <div className="bg-danger rounded p-2 pt-1 me-3">
                {/* <Linux className="opacity-50" /> */}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column align-items-start">
            <div className="text-white-400 my-2">Do you have SIEM?</div>
            <div className="d-flex">
              <button type="button" className="btn btn-primary me-2">
                Yes
              </button>
              <button type="button" className="btn btn-light">
                No
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-between mb-5 px-8 flex-sm-row">
          <div className="d-flex flex-column align-items-start mb-4 mb-sm-0">
            <div className="text-gray-400 my-2">Timeout (in seconds)</div>
            <input
              type="number"
              className="form-control w-75"
              id="timeout"
              placeholder="1"
            ></input>
          </div>
          <div className="d-flex flex-column align-items-start">
            <div className="text-gray-400 my-2">Do you have EDR?&nbsp;</div>
            <div className="d-flex">
              <button type="button" className="btn btn-light me-2">
                Yes
              </button>
              <button type="button" className="btn btn-primary">
                No
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column align-items-start align-items-sm-center mb-6 px-8">
          <div className="text-gray-400 my-4">Do you have an Anti Virus?</div>
          <div className="d-flex mb-4 align-items-center">
            <button type="button" className="btn btn-light me-2">
              No
            </button>
            <button type="button" className="btn btn-primary me-2">
              Yes
            </button>
            <input
              type="text"
              className="form-control"
              id="anti-virus-name"
              placeholder="Anti Virus Name"
            ></input>
          </div>
          <button type="button" className="btn btn-primary mt-6">
            Add Device
          </button>
        </div>
      </div>
    
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}
