import {useIntl} from 'react-intl'
import AgentPage from "../../modules/agent/AgentPage";


const AgentsPage = () => (
  
  <div className="container">
    <AgentPage/>
  </div>

)

const AgentWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <AgentsPage />
    </>
  )
}

export default AgentWrapper
